export var ClientYDirection;
(function (ClientYDirection) {
    ClientYDirection["Up"] = "up";
    ClientYDirection["Down"] = "down";
})(ClientYDirection || (ClientYDirection = {}));
export var TriggerType;
(function (TriggerType) {
    TriggerType["ExitIntend"] = "EXIT_INTENT";
    TriggerType["TimeOnPage"] = "TIME_ON_PAGE";
})(TriggerType || (TriggerType = {}));
