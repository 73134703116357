var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { getSafeEventHandlerOpts } from '../utils/touchEvent';
import { TriggerType, ClientYDirection } from '../types/NewsletterOverlay';
export var COORDINATES_Y_TO_TRIGGER_EXIT_INTENT = 150;
var TOUCH_EVENT_OPTIONS = getSafeEventHandlerOpts({
    capture: true,
    passive: false,
});
var getClientYDirection = function (prevClientY, currentClientY) {
    return prevClientY > currentClientY ? ClientYDirection.Up : ClientYDirection.Down;
};
var getTouchClientYDirection = function (initialTouchY, currentClientY) {
    return initialTouchY - currentClientY > 0
        ? ClientYDirection.Up
        : ClientYDirection.Down;
};
var isExitIntent = function (mouseState, touchState) {
    return (mouseState.direction === ClientYDirection.Up &&
        mouseState.prevClientY < COORDINATES_Y_TO_TRIGGER_EXIT_INTENT) ||
        (touchState.initialClientY !== 0 &&
            touchState.direction === ClientYDirection.Down &&
            Math.abs(touchState.viewportOffsetTop) <=
                COORDINATES_Y_TO_TRIGGER_EXIT_INTENT);
};
export var useShowOverlay = function (triggerType, timeOnPage) {
    var _a = __read(useState(false), 2), isShown = _a[0], setIsShown = _a[1];
    var _b = __read(useState({
        prevClientY: 0,
        direction: null,
    }), 2), mouseState = _b[0], setMouseState = _b[1];
    var _c = __read(useState({
        initialClientY: 0,
        currentClientY: 0,
        direction: null,
        viewportOffsetTop: 0,
    }), 2), touchState = _c[0], setTouchState = _c[1];
    var handleMouseMove = useCallback(throttle(function (e) {
        setMouseState({
            direction: getClientYDirection(mouseState.prevClientY, e.clientY),
            prevClientY: e.clientY,
        });
    }, 1000), [mouseState]);
    var handleTouchStart = useCallback(function (e) {
        var clientY = e.touches[0].clientY;
        setTouchState(__assign(__assign({}, touchState), { initialClientY: clientY }));
    }, [touchState]);
    var handleTouchMove = useCallback(throttle(function (e) {
        var clientY = e.touches[0].clientY;
        setTouchState(__assign(__assign({}, touchState), { currentClientY: clientY, direction: getTouchClientYDirection(touchState.initialClientY, clientY), viewportOffsetTop: document.documentElement.getBoundingClientRect().y }));
    }, 1000), [touchState]);
    useEffect(function () {
        if (!isShown) {
            if (triggerType === TriggerType.TimeOnPage && timeOnPage) {
                var timer_1 = setTimeout(function () {
                    setIsShown(true);
                }, timeOnPage);
                return function () { return clearTimeout(timer_1); };
            }
            if (isExitIntent(mouseState, touchState)) {
                setIsShown(true);
            }
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('touchstart', handleTouchStart, TOUCH_EVENT_OPTIONS);
            document.addEventListener('touchmove', handleTouchMove, TOUCH_EVENT_OPTIONS);
        }
        return function () {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('touchstart', handleTouchStart, TOUCH_EVENT_OPTIONS);
            document.removeEventListener('touchmove', handleTouchMove, TOUCH_EVENT_OPTIONS);
        };
    }, [triggerType, timeOnPage, mouseState, touchState, isShown]);
    return isShown;
};
